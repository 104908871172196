@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hover-top {
    @apply relative top-0 hover:-top-3  duration-300;
  }
}
@layer components {
  .menu-interval {
    @apply flex flex-col gap-5 lg:gap-10;
  }
}

.react-page-scroller-wrap > div {
  width: 100% !important;
  height: 100dvh !important;
}

/* .react-page-scroller-wrap > div {
  width: 100%;
} */
